import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { fluidRange } from 'polished';
import SEO from '../SEO';
import Layout from '../layouts/Layout';
import { dividerColor, textShadow, fg } from '../../styles/color';
import { sizes, minWidth } from '../../styles/mq';
import PostPreview from './log/PostPreview';

const Main = styled.main`
  @media (${minWidth.mobileL}) {
    margin: 0 auto;
    max-width: ${sizes.mobileL};
  }
  @media (${minWidth.mobileXL}) {
    max-width: ${sizes.mobileXL};
  }
  @media (${minWidth.laptop}) {
    max-width: ${sizes.tablet};
  }
  @media (${minWidth.desktop}) {
    max-width: ${sizes.laptop};
  }
`;

const LogHeader = styled.h2`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  line-height: 1.5rem;
  margin: 1.75rem 1rem;
  text-align: center;
`;

const LogHeaderLabel = styled.span`
  font-weight: 300;
  margin-right: 1rem;
  white-space: nowrap;
`;

const LogHeaderRule = styled.hr`
  background-color: ${dividerColor};
  border: 0;
  height: 2px;
  margin: 0;
  text-shadow: ${textShadow};
  width: 100%;
`;

const PreviewContainer = styled.section``;

const Pagination = styled.section`
  clear: both;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 2rem 1rem;
`;

const PaginateLink = styled(Link)`
  border: 1px solid ${dividerColor};
  border-radius: 0.5rem;
  color: ${fg};
  display: inline-block;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  text-decoration: none;

  ${fluidRange(
    {
      prop: `font-size`,
      fromSize: `16px`,
      toSize: `24px`,
    },
    sizes.mobileL,
    sizes.desktop,
  )}
`;

const Previous = styled.div`
  text-align: left;
`;

const Next = styled.div`
  text-align: right;
`;

export default query => {
  const {
    data: {
      allContentfulPost: { edges },
    },
    pageContext: { isFirst, isLast, prevPage, nextPage },
  } = query;

  return (
    <>
      <SEO title="Log" />
      <Layout>
        <Main>
          <LogHeader>
            <LogHeaderLabel>{isFirst ? `Recent` : `Older`} Posts</LogHeaderLabel>
            <LogHeaderRule />
          </LogHeader>
          <PreviewContainer>
            {edges.map(
              ({
                node: {
                  id,
                  slug,
                  title: { title },
                  summary: { summary },
                  dateTime,
                  featuredImage,
                },
              }) => (
                <PostPreview
                  key={id}
                  id={id}
                  link={`/log/${slug}`}
                  title={title}
                  summary={summary}
                  dateTime={dateTime}
                  featuredImage={featuredImage}
                />
              ),
            )}
          </PreviewContainer>
          <Pagination>
            <Previous>
              {!isFirst && (
                <PaginateLink to={prevPage} rel="prev">
                  &#xab; Newer Posts
                </PaginateLink>
              )}
            </Previous>
            <Next>
              {!isLast && (
                <PaginateLink to={nextPage} rel="next">
                  Older Posts &#xbb;
                </PaginateLink>
              )}
            </Next>
          </Pagination>
        </Main>
      </Layout>
    </>
  );
};

export const logQuery = graphql`
  query LogQuery($skip: Int!, $limit: Int!) {
    allContentfulPost(limit: $limit, skip: $skip, sort: { fields: dateTime, order: DESC }) {
      edges {
        node {
          id
          slug
          dateTime
          title {
            title
          }
          summary {
            summary
          }
          featuredImage {
            id
            title
            description
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
