import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mix, fluidRange } from 'polished';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { format, utcToZonedTime } from 'date-fns-tz';
import { bg, fg, dividerColor } from '../../../styles/color';
import { sansSerif, serif } from '../../../styles/fonts';
import { minWidth, sizes } from '../../../styles/mq';

const Article = styled.article`
  border-bottom: 1px solid ${dividerColor};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 2rem 1rem;

  &:last-child {
    border-bottom: 0 none;
  }
`;

const Title = styled.h2`
  font-family: ${sansSerif};
  font-size: 1.25rem;
  margin: 0;

  ${fluidRange(
    {
      prop: `font-size`,
      fromSize: `20px`,
      toSize: `36px`,
    },
    sizes.mobileL,
    sizes.desktop,
  )}
`;

const Time = styled.time`
  color: ${mix(0.4, bg, `#fff`)};
  display: block;
  font-family: ${sansSerif};
  font-size: 0.75rem;
  margin: 0.25rem 0;
  text-transform: uppercase;

  ${fluidRange(
    {
      prop: `font-size`,
      fromSize: `12px`,
      toSize: `16px`,
    },
    sizes.mobileL,
    sizes.desktop,
  )}
`;

const Details = styled.div`
  display: flex;
  flex-flow: column nowrap;

  @media (${minWidth.tablet}) {
    flex-flow: row nowrap;
  }
`;

const FeaturedImage = styled.div`
  @media (${minWidth.tablet}) {
    padding: 1rem 1rem 0 0;
    flex: 0 1 25%;
  }
`;

const FeaturedImageThumbnail = styled(Img)`
  border-radius: 0.5rem;
`;

const PostLink = styled(Link)`
  color: ${fg};
  text-decoration: none;

  &:link,
  &:visited {
    color: ${fg};
  }
`;

const Summary = styled.p`
  flex: 1 1 75%;
  font-family: ${serif};
  font-size: 1rem;

  ${fluidRange(
    {
      prop: `font-size`,
      fromSize: `16px`,
      toSize: `20px`,
    },
    sizes.mobileL,
    sizes.desktop,
  )}
`;

const PostPreview = ({ id, link, title, summary, dateTime, featuredImage }) => {
  const dateFormatted = format(
    utcToZonedTime(dateTime, `America/Los_Angeles`),
    `MMM d, yyyy — h:mma zzz`,
    {
      timeZone: `America/Los_Angeles`,
    },
  );
  return (
    <Article key={id}>
      <Title>
        <PostLink to={link}>{title}</PostLink>
      </Title>
      <Time dateTime={dateTime}>{dateFormatted}</Time>
      <Details>
        {featuredImage && (
          <FeaturedImage>
            <PostLink to={link}>
              <FeaturedImageThumbnail fluid={featuredImage.fluid} alt={featuredImage.alt} />
            </PostLink>
          </FeaturedImage>
        )}
        <Summary>{summary}</Summary>
      </Details>
    </Article>
  );
};

export default PostPreview;

PostPreview.propTypes = {
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  featuredImage: PropTypes.shape({
    alt: PropTypes.string,
    caption: PropTypes.string,
    src: PropTypes.string,
    fluid: PropTypes.object,
  }),
};

PostPreview.defaultProps = {
  featuredImage: {},
};
